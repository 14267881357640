import React from "react"
import { graphql } from "gatsby"
import DynamicZone from "../components/organisms/dynamic-zone"
const Page = ({
  data: {
    ft: { meetTheFilmaker: fm },
  },
}) => {
  return <DynamicZone components={fm.containers} />
}

export const query = graphql`
  query FilmMakers($locale: String) {
    ft {
      meetTheFilmaker(publicationState: LIVE, locale: $locale) {
        name
        slug
        containers {
          ...FilmakersDZ
        }
      }
    }
  }
`

export default Page
